<template>
  <div id="userCheckTable">
    <h3 style="text-align: center;">公开信件</h3>
    <el-table :data="tableData" style="width: 100%;">
      <el-table-column prop="messageTitle" label="标题"> </el-table-column>
      <el-table-column prop="messageType" label="分类名称" width="85px"> </el-table-column>
      <el-table-column prop="touristName" label="发布人" width="75px"> </el-table-column>
      <el-table-column prop="creatTime" label="提交时间" width="180px"> </el-table-column>
      <el-table-column label="操作" width="80px">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      layout="total, prev, pager, next, jumper"
      :page-size="5"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import {findOpenMessages} from '../../service/getData';
export default {
  name: 'UserCheckMsg',
  data() {
    return {
      tableData: [], //表格数据
      total: 0,
      currentPage: 1
    };
  },
  mounted() {
    findOpenMessages(1, 5)
      .then((data) => {
        console.log(data);
        this.tableData = data.object.list;
        this.total = data.object.total;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    handleClick(row) {
      let routeUrl = this.$router.resolve({
        name: 'GetMsgDetail',
        query: {
          row: JSON.stringify(row)
        }
      });
      window.open(routeUrl.href, 'DetailRunTime');
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      findOpenMessages(val, 5)
        .then((data) => {
          console.log(data);
          this.tableData = data.object.list;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.msg-detail {
  z-index: 999;
}
</style>
