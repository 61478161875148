import { render, staticRenderFns } from "./UserCheckMsg.vue?vue&type=template&id=0be81afc&scoped=true&"
import script from "./UserCheckMsg.vue?vue&type=script&lang=js&"
export * from "./UserCheckMsg.vue?vue&type=script&lang=js&"
import style0 from "./UserCheckMsg.vue?vue&type=style&index=0&id=0be81afc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0be81afc",
  null
  
)

export default component.exports